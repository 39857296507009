
import { defineComponent } from "vue";
import MpxIcon from "@mekari/pixel/src/js/components/Icon/index.vue";
import { APPLICATION_FETCH_HMAC_AUTH } from "@/store-types";
import { SHOW_TOAST } from "@/store-types";
import { TYPE as ToastType } from "vue-toastification";

interface Data {
  activeTabId: string;
}

export default defineComponent({
  components: {
    MpxIcon,
  },
  data(): Data {
    return {
      activeTabId: "detail",
    } as Data;
  },
  props: {
    uuid: String,
  },
  async mounted() {
    await this.loadApplication();
  },
  methods: {
    async loadApplication() {
      const app_uuid = this.uuid || "";
      try {
        await this.$store.dispatch(APPLICATION_FETCH_HMAC_AUTH, app_uuid);
      } catch (err) {
        this.handleError(err);
      }
    },
    setActiveTab(element_id: string) {
      this.activeTabId = element_id;
    },
    handleError(err: Error | any) {
      const message = err.response
        ? err.response.data.message
        : "Something went wrong. Try again";

      this.$store.dispatch(SHOW_TOAST, {
        message: message,
        type: ToastType.ERROR,
      });
    },
  },
});
