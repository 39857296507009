<template>
  <div>
    <div class="page-title pb-0">
      <h2>{{ $t("pages.applications.title") }}</h2>
      <h1>
        <router-link :to="'/dashboard/applications'">
          <mpx-icon :variant="'arrow-left'"></mpx-icon>
        </router-link>
        {{ $store.state.application.hmacAuthApplication?.name }}
      </h1>
      <div class="page-header">
        <div class="application-navbar">
          <ul class="nav nav-tabs mb-0">
            <li class="nav-item" @click="setActiveTab('detail')">
              <router-link
                :to="'/dashboard/applications/' + uuid"
                :class="`nav-link no-decoration ${
                  activeTabId == 'detail' ? 'active' : ''
                }`"
              >
                Detail
              </router-link>
            </li>
            <li class="nav-item" @click="setActiveTab('credentials')">
              <router-link
                :to="'/dashboard/applications/' + uuid + '/credentials'"
                :class="`nav-link no-decoration ${
                  activeTabId == 'credentials' ? 'active' : ''
                }`"
              >
                Credentials
              </router-link>
            </li>
            <!-- <li class="nav-item" @click="setActiveTab('logs')">
              <router-link
                :to="'/dashboard/applications/' + uuid + '/logs'"
                :class="`nav-link no-decoration ${
                  activeTabId == 'logs' ? 'active' : ''
                }`"
              >
                Logs
              </router-link>
            </li> -->
            <li class="nav-item" @click="setActiveTab('advanced')">
              <router-link
                :to="'/dashboard/applications/' + uuid + '/advanced'"
                :class="`nav-link no-decoration ${
                  activeTabId == 'advanced' ? 'active' : ''
                }`"
              >
                Advanced
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="page-content">
      <router-view />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MpxIcon from "@mekari/pixel/src/js/components/Icon/index.vue";
import { APPLICATION_FETCH_HMAC_AUTH } from "@/store-types";
import { SHOW_TOAST } from "@/store-types";
import { TYPE as ToastType } from "vue-toastification";

interface Data {
  activeTabId: string;
}

export default defineComponent({
  components: {
    MpxIcon,
  },
  data(): Data {
    return {
      activeTabId: "detail",
    } as Data;
  },
  props: {
    uuid: String,
  },
  async mounted() {
    await this.loadApplication();
  },
  methods: {
    async loadApplication() {
      const app_uuid = this.uuid || "";
      try {
        await this.$store.dispatch(APPLICATION_FETCH_HMAC_AUTH, app_uuid);
      } catch (err) {
        this.handleError(err);
      }
    },
    setActiveTab(element_id: string) {
      this.activeTabId = element_id;
    },
    handleError(err: Error | any) {
      const message = err.response
        ? err.response.data.message
        : "Something went wrong. Try again";

      this.$store.dispatch(SHOW_TOAST, {
        message: message,
        type: ToastType.ERROR,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_color";

.page-title {
  padding: 22px 24px;
  background: #f3f5f7;

  h2 {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $color-gray-slate;
  }

  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: $color-black;

    i {
      display: block;
      margin-right: 4px;
      float: left;
      margin-top: 4px;
    }
  }
}

.no-decoration {
  text-decoration: none !important;
}

.nav-tabs {
  border-bottom: 0px;

  .nav-link {
    padding: 8px 0px;
    font-weight: 400;
    color: $color-gray-slate;
  }

  .nav-item {
    margin: 8px 24px 6px 0;
  }
}

.nav-tabs .nav-link.active::after {
  position: absolute;
  bottom: -6px;
  left: 0;
  width: 100%;
  height: 2px;
  content: "";
  background-color: $color-ui-primary;
}

.nav-tabs .nav-link.active {
  color: $color-black;
}

.nav-tabs .nav-default {
  color: $color-black;
  font-weight: 600;
  display: block;
  margin-bottom: 8px;
}

.page-content {
  border-top: 1px solid $color-gray-smoke;
  background: $color-white;
  min-height: calc(100vh - 160px);
  padding: 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
</style>
